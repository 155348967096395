<template>
  <div class="login bg-gray-50">
    <div class="flex justify-center fixed items-center h-screen w-full bg-gray-50">
      <div class="hidden lg:block">
          <img src='../../assets/images/background-auth.png' alt="image_Login_Auth" className='w-full' />
        </div>
      <div class="login__form flex flex-col items-center justify-center w-7/12 ">
        <div className='mb-8 w-10'>
            <img src='../../assets/tia/tia.png' alt="logo-TIA" />
          </div>
        <div class="w-full h-full p-16">
          <div class='flex flex-col items-center justify-center mb-8'>
                <h1 class="text-sm text-center text-gray-500  font-light">Silahkan Masukkan Username dan Password</h1>
            </div>
          <div class="form ">
            <div class="mb-6">
              <TextField label="Email" type="text" placeholder="Enter Your Email Address" v-model="email" :enterKeyAction="login" border-enabled />
            </div>
            <div>
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Password</label>
              <div class="relative">
                <TextField :type="typeInput" :borderEnabled="true" id="password" name="password" placeholder="Enter Your Password" v-model="password" :enterKeyAction="login" @input="userIsTyping()" />
                <img src="@/assets/images/open-eye.svg" v-if="typeInput === 'password' && isTyping" @click="showPassword(true)" class="w-5 absolute right-4 top-1/2 transform -translate-y-1/2" />
                <img src="@/assets/images/close-eye.svg" v-if="typeInput === 'text' && isTyping" @click="showPassword(false)" class="w-5 absolute right-4 top-1/2 transform -translate-y-1/2" />
              </div>
            </div>
          </div>
          <div v-if="loginError" class="text-system-error text-center mb-6 mt-3 text-xs">
            {{ loginError }}
          </div>
          <div class="flex justify-end mt-4 mb-6">
            <p @click="toggleForgotPassword()" class="text-sm font-semibold cursor-pointer text-yellow">Forgot password</p>
          </div>
          <div class="flex justify-center ">
            <Button buttonText="Login" @action="login" size="full" class="bg-yellow"/>
          </div>
        </div>
      </div>
    </div>
    <Modal :modalVisible="visibleForgotPassword" @close="toggleForgotPassword" width="640px" id="forgot-pass">
      <template slot="modal-content">
        <template v-if="!isSuccess">
          <p class="text-2xl font-bold text-center mb-6 mt-6">Reset Your Password</p>
          <p class="text-center mb-6">Fill in your email address</p>
          <div class="p-1 mb-8 w-3/4 mx-auto">
            <TextField borderEnabled placeholder="Input Your Email Address" v-model="resetEmail" />
          </div>
          <transition name="fade" mode="out-in">
            <div class="text-system-error text-center mb-6 mt-1 text-xs" v-if="generalError">
              {{ generalError }}
            </div>
          </transition>
          <div class="text-center">
            <Button buttonText="Reset Password" @action="doReset()" />
          </div>
        </template>
        <template v-else>
          <div class="py-6">
            <div class="flex justify-center items-center mb-6">
              <img src="../../assets/tia/tia-no-desc.png" alt="Logo-TIA" class="w-36"> 
            </div>
            <p>
              Check your email at <strong>{{ resetEmail }}</strong> and follow the instruction to set a new password.
            </p>
          </div>
        </template>
      </template>
    </Modal>
    <Modal :modalVisible="false" :closeIconVisible="false" width="350px" id="customer-roles">
      <template slot="modal-content">
        <div class="customer">
          <div class="text-2xl font-bold mb-5">Login as</div>
          <div class="relative cursor-pointer">
            <TextField type="text" placeholder="Select Client" v-model="customerSelected" border-enabled disabledWhite />
            <div class="absolute right-3 top-1/2 pt-2 transform -translate-y-1/2">
              <Chevron direction="down" width="15" color="#58595B" />
            </div>
          </div>
          <div class="dropdown invisible opacity-0 -mt-3 ease-linear transition-all duration-300 left-5 top-13 transform absolute cursor-default z-10">
            <div style="width: 310px" class="bg-neutral-500 text-white text-left shadow-small py-4 px-3 rounded-lg">
              <div v-for="(person, index) in customerOptions" :key="index" class="cursor-pointer" :class="index + 1 === customerOptions.length ? '' : 'mb-2'" @click="clickCustomer(person)">
                {{ person.name }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Loading v-if="pageLoading" />
  </div>
</template>

<script>
import { error } from 'highcharts';
import VueCookies from 'vue-cookies'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Login',
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    G2Academy: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/G2Academy'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Chevron'),
    Loading: () => import(/* webpackChunkName: "Loading" */ '@/components/Illustration/Loading')
  },
  data() {
    return {
      email: '',
      password: '',
      visibleForgotPassword: false,
      visibleCustomerRoles: false,
      generalError: null,
      isSuccess: false,
      resetEmail: null,
      loginError: null,
      typeInput: 'password',
      customerOptions: [],
      customerSelected: '',
      isTyping: false
    }
  },
  computed: {
    ...mapGetters('common', ['pageLoading'])
  },
  mounted() {
    this.hideLoading()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('instructur', ['SYNC_INSTRUCTOR_EC']),
    toggleForgotPassword() {
      this.visibleForgotPassword = !this.visibleForgotPassword
    },
    toogleCustomerRoles() {
      this.visibleCustomerRoles = !this.visibleCustomerRoles
    },
    userIsTyping() {
      if (this.password.length > 0) {
        this.isTyping = true
        return
      }
      this.isTyping = false
    },
    clickCustomer(person) {
      // console.log('customer', person)
      this.showLoading()
      this.customerSelected = person.name
      localStorage.setItem('client', person.id)
      localStorage.setItem('client-name', person.name)
      this.syncInstructorData(person.id)
      setTimeout(() => {
        this.toogleCustomerRoles()
        this.hideLoading()
        setTimeout(() => {
          this.$router.push('/')
        }, 300)
      }, 2000)
    },
    syncInstructorData(customerId) {
      let params = queryString.stringify({
        ...{
          customerId
        }
      })

      this.SYNC_INSTRUCTOR_EC({
        params
      })
    },
    showPassword(status) {
      status ? (this.typeInput = 'text') : (this.typeInput = 'password')
    },
    doReset() {
      this.generalError = null
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (this.resetEmail === null) {
        this.generalError = 'Email is required!'
        return
      }
      if (!regex.test(this.resetEmail)) {
        this.generalError = 'Email format is invalid!'
        return
      }
      this.showLoading()
      this.$store
        .dispatch('customer/RESET_PASSWORD', {
          email: this.resetEmail
        })
        .then((res) => {
          this.hideLoading()
          this.isSuccess = true
        })
    },
    login: async function () {
      var result = await this.$validator.validate().then((result) => {
        if (!result) {
          return false
        }
        return true
      })

      if (!result) {
        return false
      }

      const { username, password } = this
      this.showLoading()
      this.$store
        .dispatch('customer/LOGIN', {
          email: this.email,
          password: this.password
        })
        .then((res) => {
          this.hideLoading()
          if (res.data.code == 200) {
            VueCookies.set('token', res.data.data.token, null, null, '.g2academy.co')
            localStorage.setItem('id', res.data.data.id)
            const data = res.data.data.customerRoles
            if (!data.length && res.data.data.roles.includes('SUPERADMIN')) {
              localStorage.setItem('client', null)
              localStorage.setItem('client-name', 'Super Admin')
              this.$router.push('/roles')
            } else if (data.length === 1) {
              localStorage.setItem('client', data[data.length - 1].customer.id)
              localStorage.setItem('client-name', data[data.length - 1].customer.name)
              // this.syncInstructorData(data[data.length - 1].customer.id)
              this.$router.push('/')
            } else {
              // console.log('Login here!')
              // const customer = data.find(customer => customer.customer.id === 1)
              // this.clickCustomer(customer.customer)
              // this.customerOptions = data.map((customer) => {
              //   return {
              //     ...customer.customer
              //   }
              // })
              // this.toogleCustomerRoles()
            }
          }
          if (res.data.code == 422) {
            const errorMessage = res.data.errors?.error?.[0] || 'NVALID_CREDENTIALS email or password not match '
            this.loginError = errorMessage
          }
        })
    },

    login2: function () {
      if (!form.checkValidity()) {
        return false
      }

      this.$store
        .dispatch('customer/LOGIN', {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push('/dashboard')
        })
        .catch(() => {
          alert('login fail')
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.customer {
  &:hover .dropdown {
    visibility: visible;
    opacity: 1;
    padding-top: 15px;
  }
}
.login {
  &__form {
    width: 90%;
    max-width: 820px;
  }
}
.screen {
  position: relative;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    top: 0;
    position: absolute;
    background-color: rgba($color: #fff, $alpha: 0.8);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &__image {
    background-image: url('~@/assets/images/login-screen.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 75% center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
</style>
